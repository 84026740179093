<template>
  <v-container class="general">
    <page-title :title="'Users'">

      <template slot="actions" class="d-block">
        <v-text-field
          v-model="search"
          light
          solo
          class="search-input body-1"
          label="Type to search"
          hide-details
        >
          <template slot="append">
            <font-awesome-icon
              :icon="['fas', 'search']"
              :style="{ fontSize: '20px' }"
              class="ml-1 theme-accent"
            ></font-awesome-icon>
          </template>
        </v-text-field>
      </template>
    </page-title>
    <transition name="fade" mode="out-in">
      <v-layout
        v-if="!isLoading && !users.length > 0 && !isSearching"
        class="row wrap text-center"
      >
        <v-flex class="xs12 sw-h5 font-weight-light">
          <div v-if="!search">
            {{ $t("user_list_page.nothing_created") }}
          </div>
          <div v-if="search">{{ $t("user_list_page.nothing_found") }}</div>
        </v-flex>
      </v-layout>
    </transition>
    <div v-if="!isLoading && users && users.length > 0">
      <v-layout row wrap px-3 class="align-items-center">
        <v-flex xs3>
          <v-checkbox
            :label="$t('select-all')"
            v-model="selectAll"
          ></v-checkbox>
        </v-flex>
        <template v-if="selectedUsers && selectedUsers.length > 0">
          <v-flex xs3>{{ $t("selected") }}: {{ selectedUsers.length }}</v-flex>
          <v-flex xs6>
            <v-btn round class="right red white--text" @click="massDeleteUsers">
              {{ $t("delete-selected") }}
            </v-btn>
          </v-flex>
        </template>
      </v-layout>
      <v-card>
        <div v-for="user in users" :key="user.id">
          <v-divider></v-divider>
          <v-layout row wrap text-xs-left px-3 class="align-items-center">
            <v-flex xs1>
              <v-checkbox v-model="selectedUsers" :value="user.id"></v-checkbox>
            </v-flex>
            <v-flex xs1 text-xs-center pr-1>
              <v-avatar class="tile" :size="40" color="grey lighten-3">
                <div
                  v-if="user && user.first_name && user.last_name"
                  style="text-transform:uppercase"
                >
                  <span>
                    <strong>
                      {{ user.first_name.substring(0, 1)
                      }}{{ user.last_name.substring(0, 1) }}
                    </strong>
                  </span>
                </div>
              </v-avatar>
            </v-flex>
            <v-flex xs2>
              <router-link
                :to="{
                  name: 'users_edit',
                  params: { user_id: user.id },
                }"
                class="hidden-sm-and-down"
                >{{ user.first_name }} {{ user.last_name }}</router-link
              >
            </v-flex>
            <v-flex xs2>
              <span v-if="user.phone_code && user.phone"
                >+{{ user.phone_code }} {{ user.phone }}</span
              >
            </v-flex>
            <v-flex xs2>{{ user.email }}</v-flex>
            <v-flex text-xs-right>
              <v-btn
                flat
                icon
                small
                color="grey"
                :to="{
                  name: 'users_edit',
                  params: { user_id: user.id },
                }"
              >
                <v-icon small>edit</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </div>
      </v-card>
      <div class="text-center mt-4" v-if="totalPages > 1">
        <v-pagination
          v-model="page"
          :length="totalPages"
          :total-visible="totalVisible"
          @input="listUsers"
        ></v-pagination>
      </div>
    </div>
    <AppSpeedDial
      @click="$router.push({ name: 'users_create' })"
      :hint="'Create new user'"
    />
  </v-container>
</template>

<script>
export default {
  data: () => ({
    isLoading: false,
    isSearching: false,
    users: [],
    selectedUsers: [],
    timeout: null,
    search: null,
    page: null,
    perPage: 100,
    totalUsers: null,
    totalPages: null,
    totalVisible: 5,
  }),
  computed: {
    currentPage() {
      if (this.$route.query.page) {
        return Number(this.$route.query.page);
      }
      return 1;
    },
    selectAll: {
      get: function() {
        return this.users
          ? this.selectedUsers.length == this.users.length
          : false;
      },
      set: function(value) {
        let selected = [];
        if (value) {
          for (let user of this.users) {
            selected.push(user.id);
          }
        }
        this.selectedUsers = selected;
      },
    },
  },
  watch: {
    "$route.query.page": {
      handler(to, from) {
        if (!to || !from) {
          return;
        }
        if (Number(to) !== Number(from)) {
          this.page = this.currentPage;
          this.listUsers();
        }
      },
    },
    search() {
      this.isSearching = true;

      clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        this.isSearching = false;
        this.page = 1;
        this.listUsers();
      }, 500);
    },
  },
  mounted() {
    this.page = this.currentPage;
    this.listUsers();
  },
  methods: {
    query() {
      this.$router
        .push({
          query: {
            page: this.page,
          },
        })
        .catch(() => {});
    },
    listUsers() {
      this.selectedUsers = [];
      this.isLoading = true;

      const params = {
        search: this.search ? this.search : null,
        page: this.page,
        per_page: this.perPage,
      };

      this.$api.users
        .list(params)
        .then((response) => {
          this.isLoading = false;

          this.users = response.data.data;
          this.totalUsers = response.data.pagination.total;
          this.totalPages = response.data.pagination.total_pages;

          this.query();
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    async massDeleteUsers() {
      const isConfirmed = await this.$vuetifyConfirmDialog.open(
        "User deletion",
        `You are about to delete ${this.selectedUsers.length} user/s`,
        "Cancel",
        "Confirm",
      );

      if (!isConfirmed) return;

      const params = { users: this.selectedUsers.toString() };

      this.$api.users
        .mass_delete(params)
        .then((response) => {
          this.$store.dispatch("addNotification", {
            message: `${this.selectedUsers.length} user/s deleted`,
          });

          this.page = 1;

          this.listUsers();
        })
        .catch((error) => this.errorMessageShow(error));
    },
  },
};
</script>

<style>
.align-items-center {
  align-items: center;
}
</style>
